import React, { useMemo } from 'react'

import { ColorPicker } from '@fullfabric/alma-mater'

import { useTenantColors } from 'apps/EmailTemplates/hooks/useTenantColors'

import styles from './styles.module.scss'

function rgbToHex(r, g, b) {
  const c = (v) => {
    const hex = v.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  return '#' + c(r) + c(g) + c(b)
}

export const FontColor = ({ editor }) => {
  const value = editor.getAttributes('textStyle').color || 'rgb(0, 0, 0)'
  const colors = useTenantColors()

  const hex = useMemo(() => {
    const [r, g, v] = value.includes('rgb') ? value.match(/\d+/g) : [0, 0, 0]
    return rgbToHex(parseInt(r), parseInt(g), parseInt(v))
  }, [value])

  return (
    <ColorPicker
      id='font-color'
      className={styles.container}
      onChange={(value) => editor.chain().focus().setColor(value).run()}
      value={hex || ''}
      disableClear
      small
      defaultColors={colors}
    />
  )
}
